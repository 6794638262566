<template>
  <v-chip
    large
    :color="currentLevel.color"
    text-color="white"
    v-if="currentLevel"
  >
    <v-avatar
      left
      color="white"
    >
      <v-icon :color="currentLevel.color">{{$icon('i.Trophy')}}</v-icon>
    </v-avatar>
    {{$t('t.LevelName', {name: $t(currentLevel.name)})}}
  </v-chip>
</template>

<script>
export default {
  data () {
    return {
      rewardLevels: [
        {
          color: '#cc6633',
          name: this.$t('t.Bronze'),
          threshold: 50
        },
        {
          color: '#c0c0c0',
          name: this.$t('t.Silver'),
          threshold: 75
        },
        {
          color: '#ffd700',
          name: this.$t('t.Gold'),
          threshold: 95
        }
      ]
    }
  },
  computed: {
    currentLevel () {
      return [...this.rewardLevels].sort((a, b) => b.threshold - a.threshold).find(l => this.score >= l.threshold)
    }
  },
  props: {
    score: Number
  }
}
</script>
